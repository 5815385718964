import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-primary-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './primary-button.component.html',
  styleUrl: './primary-button.component.scss'
})
export class PrimaryButtonComponent {
  @Input() disabled: boolean = false;
  @Input() label: string;
  @Input() withLoader: boolean;
  @Input() loading: boolean;
  @Input() width: string = 'w-100';

  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  clicked(): void {
    this.onClick.emit();
  }
}
